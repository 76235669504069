const ArchiveData = [
    {
        id:'1',
        title:'Patisserie Francaise',
        year:'2019',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://patisseriefrancaise.sa'
    },
    {
        id:'2',
        title:'PC Store',
        year:'2019',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://pcstoresa.com/'
    },
    {
        id:'3',
        title:'Rahma Charity',
        year:'2020',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://rahmmh.com/'
    },
    {
        id:'4',
        title:'Dentin',
        year:'2021',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://dentin.sa/'
    },
    {
        id:'5',
        title:'Ber El Haddar Charity',
        year:'2020',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://ber-alhaddar.org/'
    },
    {
        id:'6',
        title:'3d Mobile',
        year:'2018',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://3dmobile.sa.com/'
    },
    {
        id:'7',
        title:'PC Store',
        year:'2019',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://pcstoresa.com/'
    },
    {
        id:'8',
        title:'Gulf Studs',
        year:'2021',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://gulfstuds.com/'
    },
    {
        id:'9',
        title:'Maddak',
        year:'2020',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://madakk.com/'
    },
    {
        id:'10',
        title:'Teh Hospital',
        year:'2020',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://teh.sa/'
    },
    {
        id:'11',
        title:'PC Store',
        year:'2019',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://pcstoresa.com/'
    },
    {
        id:'12',
        title:'Al Rosan Company',
        year:'2019',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://alrosan.com.sa/'
    },
    {
        id:'13',
        title:'Pro Touch',
        year:'2019',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://protouch.sa/'
    },
    {
        id:'14',
        title:'Dimofinf Store',
        year:'2021',
        madeAt:'Dimofinf',
        technology:'wordpress',
        link:'https://projects.dimofinf.net/shop10/'
    }
];


export default ArchiveData;