import React , { useEffect } from 'react';

import { Container , Row , Col  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import ArchiveData from '../../Data/Archive';
import './Archive.scss';


const Archive = () => {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return ( 
        <>
            <section className="archive-parent">
                

                <Container>
                    <Row>
                        <Col>
                            <div className="heading">
                                <h2>Archive</h2>
                                <p>A big list of things I’ve worked on</p>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <table className='archive-table'>
                                <thead>
                                    <tr>
                                        <th>Year</th>
                                        <th>Title</th>
                                        <th>Made at</th>
                                        <th>Built With</th>
                                        <th>Link</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ArchiveData.length ? ArchiveData.map(item => {
                                            return(
                                                <tr ke={item.id}>
                                                    <td className='year'>{item.year}</td>
                                                    <td className='title'>{item.title}</td>
                                                    <td>{item.madeAt}</td>
                                                    <td>{item.technology}</td>
                                                    <td>
                                                        <a href={item.link} className='link' target="_blank" rel="noreferrer">
                                                            <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                                        </a>
                                                    </td>
                                                </tr>
                                            );
                                        }):null
                                    }
                                    
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}

export default Archive;