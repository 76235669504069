import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF , faGithub, faInstagram , faLinkedinIn ,  faTwitter } from '@fortawesome/free-brands-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import './Footer.scss';
const ContactSec = () => {
    return ( 
        <>
            <footer className="contact-sec" id="contact">
                <div className="cont">
                    <div className="heading green-txt">
                        <span className="number">04.</span>
                        <p>What's Next?</p>
                    </div>
                    <h2>Get In Touch</h2>
                    <p>
                        My inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you asap!
                    </p>
                    <a href="mailto:omarsabry512@gmail.com" className="transparent-btn">
                        Say Hello
                    </a>
                </div>

                <div className="footer-social-icons-parent">
                    <a href="https://github.com/omarsabry512" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faGithub}/>
                    </a>

                    <a href="tel:+201068983384" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faPhone}/>
                    </a>

                    <a href="https://www.linkedin.com/in/omarsabry512/" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faLinkedinIn}/>
                    </a>

                    <a href="https://www.facebook.com/omar.sabryii" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faFacebookF}/>
                    </a>

                    <a href="https://www.instagram.com/omaar_sabry/" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faInstagram}/>
                    </a>

                    <a href="https://twitter.com/omaar_sabry" className="social-icon" target="_blank" rel='noreferrer'>
                        <FontAwesomeIcon icon={faTwitter}/>
                    </a>
                    <span className="line"></span>
                </div>
                <div className="copy-rights">
                    <a href="https://brittanychiang.com/" className="link green-txt"   target="_blank" rel='noreferrer'>Designed by Brittany</a> &  
                    <a href="/" className="green-txt"> Built By Omar Sabry</a>
                </div>

            </footer>
        </>
    );
}

export default ContactSec;