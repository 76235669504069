import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF , faGithub, faInstagram , faLinkedinIn ,   faTwitter } from '@fortawesome/free-brands-svg-icons';
import {faPhone} from '@fortawesome/free-solid-svg-icons';
import './Contact.scss'
const Contact = () => {
    return ( 
        <>
            <div className="social-icons-parent">
                <a href="https://github.com/omarsabry512" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faGithub}/>
                </a>

                <a href="tel:+201068983384" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faPhone}/>
                </a>

                <a href="https://www.linkedin.com/in/omarsabry512/" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faLinkedinIn}/>
                </a>

                <a href="https://www.facebook.com/omar.sabryii" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faFacebookF}/>
                </a>

                <a href="https://www.instagram.com/omaar_sabry/" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faInstagram}/>
                </a>

                <a href="https://twitter.com/omaar_sabry" className="social-icon" target="_blank" rel='noreferrer'>
                    <FontAwesomeIcon icon={faTwitter}/>
                </a>

                <span className="line"></span>
            </div>

            <div className="mail-parent">
                <a href="mailto:omarsabry512@gmail.com">omarsabry512@gmail.com</a>
                <span className="line"></span>

            </div>
        </>
    );
}

export default Contact;