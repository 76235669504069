import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./HeroSec.scss";
const HeroSec = () => {
  return (
    <>
      <section className="hero-sec">
        <Container>
          <Row>
            <Col>
              <div className="cont-txt">
                <h1>Omar Sabry</h1>
                <h2>I build things for the web.</h2>
                <p className="entry-txt">
                  Computer Engineer Professionally connected with the web
                  development industry and information technology for many
                  years. Well-organised person, problem solver, independent
                  employee with high attention to detail. Interested in the
                  entire frontend spectrum and working on ambitious projects
                  with positive people.
                </p>

                <a
                  href="https://omarsabry.com/cv/Omar_Sabry_Front_End.pdf"
                  download="Omar_Sabry_Front_End"
                  className="btn transparent-btn"
                >
                  Check Out My Resume
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default HeroSec;
