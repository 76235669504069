import React from "react";
import { Container, Navbar, Nav } from "react-bootstrap";
import "./Header.scss";
const Header = () => {
  return (
    <>
      <header className="fixed-top">
        <Container fluid className="header-container">
          <Navbar expand="lg">
            <Navbar.Brand href="/">
              <span>&lt; Omar /></span>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">
                <a href="/#aboutMe" className="nav-link">
                  <span className="green-txt">01.</span>About
                </a>
                <a href="/#workSec" className="nav-link">
                  <span className="green-txt">02.</span>Experience
                </a>
                <a href="/#projects" className="nav-link">
                  <span className="green-txt">03.</span>Work
                </a>
                <a href="/#contact" className="nav-link">
                  <span className="green-txt">04.</span>Contact
                </a>
                <a
                  href="https://omarsabry.com/cv/Omar_Sabry_Front_End.pdf"
                  download="Omar_Sabry_Front_End"
                  className="btn transparent-btn"
                >
                  Resume
                </a>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
        </Container>
      </header>
    </>
  );
};

export default Header;
