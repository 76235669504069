import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

  console.clear();
  console.log("%c<Omar Sabry/>", "color:#C4FFDD; font-family:'Ubuntu'; display: block;font-weight:bold; font-size:48px;");
  console.log("%cDo You Need Any Thing ?!", " font-family:'Ubuntu';font-weight:100; font-size:18px; ");
  console.log("%cJust Call Me Omar: 01068983384", "line-height: 3em; padding: 0.5em; text-align: center; border: 1px dotted #aaa;  font-size: 14px;")


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
