import React , {useState} from 'react';
import { Container , Row , Col  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faReact , faWordpress , faNpm  , faSass   } from '@fortawesome/free-brands-svg-icons';
import FeatureProjects from '../../../Data/FeatureProjects';


import './ProjectSec.scss';
const ProjectSec = () => {

    const [showMoreProjects , setShowMoreProjects ] = useState(false);
    return ( 
        <>
            <section className="projects-sec" id="projects">
                <Container>
                    <Row>
                        <Col>
                            <div className="sec-heading">
                                <h2>
                                    <span className="green-txt">03. </span>  
                                    Some Projects I’ve Built
                                </h2>
                                <span className="line"></span>
                            </div>

                            <div className="projects-parent">
                                {
                                    FeatureProjects.length ? FeatureProjects.map(project => {
                                        return (
                                            <div className="cont" key={project.id}>
                                                <div className="cont-txt">
                                                    <span className="green-txt">Featured Project</span>
                                                    <h2 className="project-name">
                                                        <a href={project.link} target="_blank" rel='noreferrer'>{project.title}</a>
                                                    </h2>

                                                    <div className="description">
                                                        <p>{project.description}</p>
                                                    </div>

                                                    <div className="tools">
                                                    {
                                                        project.tools.map(tool => {
                                                            return (
                                                                <span key={tool}>                                                                      
                                                                    {tool === 'sass' ?<FontAwesomeIcon icon={faSass}/> :null}
                                                                    {tool === 'React js' ?<FontAwesomeIcon icon={faReact}/> :null}
                                                                    {tool === 'Npm Packages' ?<FontAwesomeIcon icon={faNpm}/> :null}
                                                                    {tool === 'Wordpress Api'  ?<FontAwesomeIcon icon={faWordpress}/> :null}
                                                                    {tool === 'Wordpress Cms' ?<FontAwesomeIcon icon={faWordpress}/> :null}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                    <div className="links-parent">
                                                        <a href={project.link} target="_blank" rel='noreferrer'>
                                                            <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="cont-img">
                                                    <div className="img-parent">
                                                        <img src={project.image} alt="twasul website" />
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }):null
                                }

                            </div>
                        </Col>
                    </Row>
                </Container>

            </section>
        </>
    );
}

export default ProjectSec;