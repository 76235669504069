const FeatureProjects = [
    {
        id:'1',
        title:'Platraw',
        link:'https://platraw.com',
        description:'Platraw is a smart solution for the business sector to get all your supplies from one vendor at appropriate prices and fast delivery!',
        image:'https://omarsabry.com/Assets/platraw.png',
        tools:['sass', 'React js' , 'Npm Packages']
    },
    {
        id:'2',
        title:'Swani Bookstore',
        link:'https://www.skitab.com',
        description:'An electronic market and a Saudi cultural platform, to facilitate the process of buying, selling, and circulating books among members of society',
        image:'https://omarsabry.com/Assets/bookStore.png',
        tools:['sass', 'React js' , 'Npm Packages']
    },
    {
        id:'3',
        title:'Monarch Renovations',
        link:'https://monarchrenovations.com.au/',
        description:'Monarch is a Company which has a huge reputation for design, excellent art, and reliable service, and can deal with all-new, small, or luxury kitchens & bathrooms.',
        image:'https://omarsabry.com/Assets/monarch.jpg',
        tools:['Wordpress Cms']
    },
    {
        id:'4',
        title:'Twasul',
        link:'https://twasul.com',
        description:'Tawasul electronic newspaper website is an advertising window through which your products and services reach millions of views',
        image:'https://omarsabry.com/Assets/twasul.png',
        tools:['React js', 'sass' , 'Npm Packages', 'Wordpress Api']
    },
    {
        id:'5',
        title:'Mosabqat',
        link:'http://winyourtime24.com/',
        description:'it\'s an competition web app using React Library and sass to let the users to compeat with each others to earn coins',
        image:'https://omarsabry.com/Assets/mosabqat.jpg',
        tools:['sass', 'React js' , 'Npm Packages']
    },
    {
        id:'6',
        title:'Arabian Energy & Engineering Company',
        link:'https://arabianee.com',
        description:'The Arabian Energy & Engineering is a leading construction contractor in the Sultanate of Oman having executed number of projects in the last years. AEE has become a name synonymous with integrity and versatility.',
        image:'https://omarsabry.com/Assets/arabianee.jpg',
        tools:['sass', 'React js' , 'Npm Packages']
    },
    {
        id:'7',
        title:'Sada News',
        link:'https://slaati.com/',
        description:'website for Newspaper , get all the new news and blog in saudi arabia',
        image:'https://omarsabry.com/Assets/sada.png',
        tools:['React js', 'sass' , 'Npm Packages', 'Wordpress Api']
    },
];

export default FeatureProjects;