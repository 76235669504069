import React from 'react';
import { Container , Row , Col  } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHtml5 , faWordpress , faPhp  , faJsSquare , faCss3   } from '@fortawesome/free-brands-svg-icons';
import {  faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import otherProjects from '../../../Data/otherProjects';
import { Link } from "react-router-dom";
import './OtherProjects.scss';


const OtherProjects = () => {
    return ( 
        <>
            <section className="other-projects-parent">
                <div className="heading">
                    <h2>Other Noteworthy Projects</h2>
                    <Link to="archive">view  the  archive</Link>
                </div>

                <Container>

                <Row>
                    {
                        otherProjects.length ? otherProjects.map(project => {
                            return (
                                    <Col xs={12} lg={4}  key={project.id}>
                                        <div className="project-parent">
                                            <div className="project-heading">
                                                <FontAwesomeIcon icon={faFolder} className="folder-icon" />
                                                <a href={project.link} className="project-link" target="_blank" rel="noreferrer">
                                                    <FontAwesomeIcon icon={faExternalLinkAlt}/>
                                                </a>
                                            </div>
                                            <div className="project-content">
                                                <h2 className="project-name">
                                                    <a href={project.link} className="project-link" target="_blank" rel="noreferrer">{project.title}</a>
                                                </h2>

                                                <p className="project-description">
                                                    {project.description}
                                                </p>

                                                <p className="project-technologies">
                                                    {
                                                        project.technology.map(tech => {
                                                            return(
                                                                <span key={tech}>
                                                                    {tech === 'Html' ?<FontAwesomeIcon icon={faHtml5}/> :null}
                                                                    {tech === 'css' ?<FontAwesomeIcon icon={faCss3}/> :null}
                                                                    {tech === 'Javascript' ?<FontAwesomeIcon icon={faJsSquare}/> :null}
                                                                    {tech === 'wordpress'  ?<FontAwesomeIcon icon={faWordpress}/> :null}
                                                                    {tech === 'php' ?<FontAwesomeIcon icon={faPhp}/> :null}
                                                                </span>
                                                            )
                                                        })
                                                    }
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                            )}
                        ):null
                    }
                        <div className="text-center w-100">
                            <Link to="archive" className="btn transparent-btn">Check All Projects</Link>
                        </div>
                    
                </Row>

                </Container>
            </section>
        </>
    );
}

export default OtherProjects;