import Contact from './Components/Contact/Contact';
import Header from './Components/Layouts/Header/Header';
import Home from './Components/Home/Index';
import ContactSec from './Components/Layouts/Footer/Footer';
import Archive from './Components/Archive';
import {   BrowserRouter , Routes , Route } from "react-router-dom";
import './App.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
          <Routes>
            <Route path="/" exact element={<Home/>} />
            <Route path="archive" element={<Archive/>} />
          </Routes>
          <Contact/>
          <ContactSec/>
        </BrowserRouter>
        
    </div>
    
  );
}

export default App;
