const otherProjects = [
    {
        id:'1',
        title:'Dimofinf company website',
        description:'Dimofinf with commercial registrar numbered 1010213247 is a pioneering company in information technology field with more than 20 years experience.',
        link:'https://www.dimofinf.net/',
        technology:['Html' ,'css', 'Javascript']
    }, 
    {
        id:'2',
        title:'Omar Sabry Portfolio',
        description:'the old verion of my personal website that shows my work at sometimes',
        link:'http://old.omarsabry.com/',
        technology:['Html' ,'css', 'Javascript']
    },
    {
        id:'3',
        title:'Ignite',
        description:'Ignite for Investment and Real Estate Development “Abo Raya Group” was established in 2017. It is an extension of a twenty years of experience journey in the Kingdom of Saudi Arabia.',
        link:'https://ignite-egypt.com/',
        technology:['wordpress' ,'php']
    },
    {
        id:'4',
        title:'Dimofinf Blog',
        description:'A tech blog to dimofinf company that has more of useful articles about hosting , marketing and programming',
        link:'https://www.dimofinf.net/blog/',
        technology:['wordpress' ,'php']
    },
    {
        id:'5',
        title:'R2n Company',
        description:'R2N Ready mix is a construction company which has many projects',
        link:'https://r2n-readymix.com/',
        technology:['wordpress' ,'php']
    },
    {
        id:'6',
        title:'Alienor Organics',
        description:'Alienor is an ecommerce which presents you handcrafted & safe skin, body & hair products depending on nature',
        link:'https://alienor-organics.com/',
        technology:['wordpress' ,'php']
    }
]


export default otherProjects;