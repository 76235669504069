import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { Container, Row, Col } from "react-bootstrap";
import "./WorkSec.scss";
const WorkSec = () => {
  return (
    <>
      <section className="work-sec" id="workSec">
        <Container>
          <Row>
            <Col xs={12} lg={12} className="mx-auto">
              <div className="sec-heading">
                <h2>
                  <span className="green-txt">02. </span>
                  Where I’ve Worked
                </h2>
                <span className="line"></span>
              </div>
              <Tabs className="work-links-parent">
                <TabList className="work-links-controls">
                  <Tab>Crowd Analyzer</Tab>
                  <Tab>Extend the Ad Network</Tab>
                  <Tab>Dimofinf</Tab>
                  <Tab>Ripe Soltuions</Tab>
                  <Tab>Freelancer</Tab>
                </TabList>
                <div className="tabs-parent">
                  <TabPanel>
                    <div className="cont-txt">
                      <h3>
                        Senior Front End Developer{" "}
                        <a
                          href="https://www.crowdanalyzer.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="green-txt"
                        >
                          Crowd Analyzer
                        </a>
                      </h3>
                      <p className="time">August 2023 - Present</p>
                      <ul className="description">
                        <li>Develop websites with react js</li>
                        <li>Handle Deployments , Pipelines</li>
                        <li>Managing software workflow</li>
                        <li>Review code for other team members </li>
                        <li>
                          Build reusable Components and libraries for future use
                        </li>
                        <li>
                          Optimize application for maximum speed and scalability
                        </li>
                        <li>
                          Collaborate with other team members and stakeholders
                        </li>
                        <li>Fixing bugs and testing for usability</li>
                        <li>Following SEO best practices</li>
                      </ul>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="cont-txt">
                      <h3>
                        Senior Front End Developer{" "}
                        <a
                          href="https://extendad.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="green-txt"
                        >
                          Extend the Ad Network
                        </a>
                      </h3>
                      <p className="time">March 2022 - August 2023</p>
                      <ul className="description">
                        <li>Develop websites with react js</li>
                        <li>
                          Make the ui section of website and integrate code with
                          Api
                        </li>
                        <li>
                          Build reusable Components and libraries for future use
                        </li>
                        <li>
                          Optimize application for maximum speed and scalability
                        </li>
                        <li>
                          Collaborate with other team members and stakeholders
                        </li>
                        <li>Managing software workflow</li>
                        <li>Fixing bugs and testing for usability</li>
                        <li>Following SEO best practices</li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="cont-txt">
                      <h3>
                        Front End Developer{" "}
                        <a
                          href="https://www.dimofinf.net/"
                          target="_blank"
                          rel="noreferrer"
                          className="green-txt"
                        >
                          Dimofinf
                        </a>
                      </h3>
                      <p className="time">August 2018 - February 2022</p>
                      <ul className="description">
                        <li>Develop websites with react js</li>
                        <li>Develop wordpress websites and Plugins</li>
                        <li>
                          Make the ui section of website and integrate code with
                          Api
                        </li>
                        <li>
                          Build reusable Components and libraries for future use
                        </li>
                        <li>
                          Optimize application for maximum speed and scalability
                        </li>
                        <li>
                          Collaborate with other team members and stakeholders
                        </li>
                        <li>Managing software workflow</li>
                        <li>Fixing bugs and testing for usability</li>
                        <li>Following SEO best practices</li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="cont-txt">
                      <h3>
                        Front End Developer{" "}
                        <a
                          href="https://riperesolution.com/"
                          target="_blank"
                          rel="noreferrer"
                          className="green-txt"
                        >
                          Ripe Resolution
                        </a>
                      </h3>
                      <p className="time">June 2020 - November 2021</p>
                      <ul className="description">
                        <li>Develop Websites with wordpress</li>
                        <li>Fixing bugs and testing for usability</li>
                        <li>Optimize applications for maximum speed</li>
                      </ul>
                    </div>
                  </TabPanel>

                  <TabPanel>
                    <div className="cont-txt">
                      <h3>
                        Front End Developer{" "}
                        <a
                          href="/"
                          target="_blank"
                          rel="noreferrer"
                          className="green-txt"
                        >
                          Freelance
                        </a>
                      </h3>
                      <p className="time">March 2018 - present</p>
                      <ul className="description">
                        <li>
                          Creating static websites pages with html, css, js
                        </li>
                        <li>
                          Creating Websites with modern javascript frame works
                          and libraries ( angularjs, reactjs ){" "}
                        </li>
                        <li>Creating Websites with wordpress Cms</li>
                        <li>
                          Installing & editing some WordPress themes for clients{" "}
                        </li>
                        <li>Fix errors to reach stability to launch</li>
                      </ul>
                    </div>
                  </TabPanel>
                </div>
              </Tabs>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default WorkSec;
